<template>
    <section class="pt-lg-8 pt-4">
        <div class="container full-height pt-lg-4 pt-8">
            <login-heading heading="Basic Information" class="mb-4" size="lg">
                <template #prepend>
                    <span class="text-2 font-inter-bold fs--1 mb-4">REGISTRAION 1/4</span>
                </template>
            </login-heading>
            <div class="row">
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input label="Mobile Number Verification" class="c-input label-bold">
                        <template #prepend>
                            <div class="text-white fl-x pr-2"><span class="mr-1">+91</span><span class="d-inline"><i
                                class="fa fa-caret-down"></i></span></div>
                        </template>
                    </validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input type="password" label="Password" class="c-input"></validated-input>
                    <p class="mt-3 mb-3 text-3 fs--1">Password must contain at least 8 characters, including upper &
                        lower
                        case letters, numbers and
                        special characters.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input label="Email ID" class="c-input"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input type="password" label="Confirm Password" class="c-input"></validated-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input label="Confirm Email ID" class="c-input"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input label="Add Profile Picture" class="c-input label-bold">
                        <template #append>
                            <div class="text-2"><span class="d-inline"><i
                                class="fa fa-upload"></i></span></div>
                        </template>
                    </validated-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4 col-md-6">
                    <btn block text="Next" @click="$router.push({name:'RegisterContactInfo'})"
                         class="mt-4 mb-7 op-08 border-r--2 auth-button py-3"></btn>
                </div>
                <div class="col-12">
                    <span class="text-2 font-inter-medium fs--1 mt-5 op-06"># Registration Completed: 0/4</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LoginHeading from '@components/login/LoginHeading';

export default {
    name: 'RegisterBasicInformation',
    components: { LoginHeading }
};
</script>

<style scoped>

</style>
